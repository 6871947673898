import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import iconFiles from "../assets/Files.svg";
import "../styles/RgbResults.css";
import XyzToRgb from "./math/XyzToRgb";
import rgbToHex from "./math/RgbToHex";
import { ColorInfo } from "./math/ColorInfo";

const RgbResults = (X, Y, Z, adaptation) => {
  const { t } = useTranslation();

  const [resultData, setResultData] = useState([]);
  const {
    sX, sY, sZ,
    Xd, Yd, Zd,
    ExtendedR, ExtendedG, ExtendedB,
    R01, G01, B01,
    R8bit, G8bit, B8bit,
    NR8b, NG8b, NB8b,
    R15, G15, B15,
    R10, G10, B10,
    R16, G16, B16,
    R32, G32, B32,
    destinationLab,
  } = XyzToRgb(X, Y, Z, adaptation);

  useEffect(() => {
    const convertToRgb = () => {
      const formats = [
        {
          name: t("Extended"),
          values: [
            Math.max(0,parseFloat(ExtendedR).toFixed(4)),
            Math.max(0,parseFloat(ExtendedG).toFixed(4)),
            Math.max(0,parseFloat(ExtendedB).toFixed(4)),
          ],
        },
        {
          name: t("Range"),
          values: [
            parseFloat(R01).toFixed(4),
            parseFloat(G01).toFixed(4),
            parseFloat(B01).toFixed(4),
          ],
        },
        { name: "8-" + t('bit'), values: [R8bit, G8bit, B8bit] },
        { name: "8-" + t('bit') + t("NoRounding"), values: [parseFloat(NR8b).toFixed(4), parseFloat(NG8b).toFixed(4), parseFloat(NB8b).toFixed(4)] },
        { name: "10-" + t('bit') + "(0-1023)", values: [R10, G10, B10] },
        { name: "15+1-" + t('bit') + t("PS_Range"), values: [R15, G15, B15] },
        { name: "16-" + t('bit') + "(0-65535)", values: [R16, G16, B16] },
        { name: "32-" + t('bit') + "(0-4294967295)", values: [R32, G32, B32] },
      ];
      setResultData(formats);
    };
    convertToRgb();
  }, [X, Y, Z, ExtendedR, ExtendedG, ExtendedB, t, adaptation]);

  const hex = rgbToHex(R8bit, G8bit, B8bit);
  //const misc = ColorInfo(l, a, b, X, Y, Z, sX, sY, sZ, hex, adaptation, destinationLab)

  const table = () => {
    const hasNaNInResultData = resultData.some((format) =>
      format.values.some((value) => isNaN(value))
    );

    if (hasNaNInResultData) {
      return (
        <div className="converter-container">
          <table className="conversion-table no-border">
            <thead>
              <tr>
                <th className="tableHeaderCell">Format</th>
                <th className="tableHeaderCell">R</th>
                <th className="tableHeaderCell">G</th>
                <th className="tableHeaderCell">B</th>
              </tr>
            </thead>
            <tbody className="table-style">
              <tr>
                <td className="no-border" style={{ color: "var(--danger)" }}>
                  {t("fillLAB")}
                </td>
                <td className="no-border"></td>
                <td className="no-border"></td>
                <td className="no-border"></td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }


    return (
      <div className="converter-container">
        <TableColor resultData={resultData} />
        <div className="preview-text">{t("ColorPreview")}</div>
        <div
          className="color-preview"
          style={{ backgroundColor: `${hex}` }}
        />
        {/* {misc.table} */}
      </div>
    );
  };

  return {
    table: table(),
    //data: { l, a, b, R8bit, G8bit, B8bit, luvU: misc.luvU, luvV: misc.luvV, destinationLab, misc: misc.table },
    data: { R8bit, G8bit, B8bit, sX, sY, sZ, Xd, Yd, Zd, hex, adaptation, destinationLab },
    rgbAlertData: { ExtendedR, ExtendedG, ExtendedB },
  };
};
export default RgbResults;



const TableColor = ({ resultData }) => {
  const [copiedValue, setCopiedValue] = useState("");
  const handleCellClick = (value) => {
    navigator.clipboard.writeText(value);
    setCopiedValue(value);
  };

  const handleCellMouseEnter = () => {
    document.body.style.cursor = "pointer";
  };
  const handleCellMouseLeave = () => {
    document.body.style.cursor = "auto";
  };

  return (
    <div>
      <table className="conversion-table">
        <thead>
          <tr>
            <th className="tableHeaderCell">Format</th>
            <th className="tableHeaderCell">R</th>
            <th className="tableHeaderCell">G</th>
            <th className="tableHeaderCell">B</th>
          </tr>
        </thead>
        <tbody className="table-style">
          {resultData.map((format, index) => (
            <tr key={index}>
              <td>{format.name}</td>
              {format.values.map((value, i) => (
                <td
                  key={i}
                  onClick={() => handleCellClick(value)}
                  onMouseEnter={handleCellMouseEnter}
                  onMouseLeave={handleCellMouseLeave}
                >
                  {value}
                  <div
                    style={{
                      float: "right",
                      verticalAlign: "middle",
                      display: "block",
                    }}
                  >
                    <img
                      src={iconFiles}
                      alt="Copy"
                      style={{
                        marginTop: "-3px",
                        width: "14px",
                        opacity: "0.4",
                        verticalAlign: "middle",
                      }}
                    />
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
