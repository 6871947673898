import ChromaticAdaptation from "./ChromaticAdaptation";
import OETF from "./OETF";
import XyzToRGBMatrix from "./XyzToRGBMatrix";
import XyztoLab from "./XyzToLab";

const XyzToRgb = (X, Y, Z, colorTransformation) => {
  const { sourceColorWhite, destinationColorWhite, destinationColorSpace, adaptationMethod, methodSelect } = colorTransformation;
  // source X,Y,Z values 
  const sX = parseFloat(X);
  const sY = parseFloat(Y);
  const sZ = parseFloat(Z);

  //Chromatic Adaptation
  const adapted = ChromaticAdaptation(X, Y, Z, sourceColorWhite, destinationColorWhite, adaptationMethod, methodSelect)
  X = adapted.Xd
  Y = adapted.Yd
  Z = adapted.Zd
  // Convert XYZ to Lab
  const destinationLab = XyztoLab(X, Y, Z, destinationColorWhite)

  // Convert XYZ to RGB
  const RGB = XyzToRGBMatrix(X, Y, Z, destinationColorSpace)
  const ExtendedR = Math.max(0, RGB.R);
  const ExtendedG = Math.max(0, RGB.G);
  const ExtendedB = Math.max(0, RGB.B);
  // Results for the table
  // Gamma Correction
  const { R: correctedR, G: correctedG, B: correctedB } = OETF(ExtendedR, ExtendedG, ExtendedB, destinationColorSpace);
  // Clipped to non-negative
  let ClippedR = (correctedR < 0) ? 0 : correctedR;
  let ClippedG = (correctedG < 0) ? 0 : correctedG;
  let ClippedB = (correctedB < 0) ? 0 : correctedB;
  // 0-1 range, float
  let R01 = (ClippedR > 1) ? 1 : ClippedR;
  let G01 = (ClippedG > 1) ? 1 : ClippedG;
  let B01 = (ClippedB > 1) ? 1 : ClippedB;
  let R8bit, G8bit, B8bit,
    NR8b, NG8b, NB8b,
    R15, G15, B15,
    R10, G10, B10,
    R16, G16, B16,
    R32, G32, B32

  if (destinationColorSpace === "Rec709normative") {

    //8-bit
    R8bit = Math.floor(16 + (235 - 16) * R01 + 0.5);
    G8bit = Math.floor(16 + (235 - 16) * G01 + 0.5);
    B8bit = Math.floor(16 + (235 - 16) * B01 + 0.5);
    //8-bit no rounding
    NR8b = 16 + (235 - 16) * R01.toFixed(4);
    NG8b = 16 + (235 - 16) * G01.toFixed(4);
    NB8b = 16 + (235 - 16) * B01.toFixed(4);
    //10-bit
    R10 = Math.floor(64 + (940 - 64) * R01 + 0.5);
    G10 = Math.floor(64 + (940 - 64) * G01 + 0.5);
    B10 = Math.floor(64 + (940 - 64) * B01 + 0.5);
    //15+1-bit
    R15 = Math.floor(2048 + (30080 - 2048) * R01 + 0.5);
    G15 = Math.floor(2048 + (30080 - 2048) * G01 + 0.5);
    B15 = Math.floor(2048 + (30080 - 2048) * B01 + 0.5);
    //16-bit 
    G16 = Math.floor(4096 + (60160 - 4096) * G01 + 0.5);
    R16 = Math.floor(4096 + (60160 - 4096) * R01 + 0.5);
    B16 = Math.floor(4096 + (60160 - 4096) * B01 + 0.5);
    //32-bit
    R32 = Math.floor(268435456 + (3942645760 - 268435456) * R01 + 0.5);
    G32 = Math.floor(268435456 + (3942645760 - 268435456) * G01 + 0.5);
    B32 = Math.floor(268435456 + (3942645760 - 268435456) * B01 + 0.5);
  } else {

    //8-bit
    R8bit = Math.floor(255 * R01 + 0.5);
    G8bit = Math.floor(255 * G01 + 0.5);
    B8bit = Math.floor(255 * B01 + 0.5);
    //8-bit no rounding
    NR8b = (R01 * 255);
    NG8b = (G01 * 255);
    NB8b = (B01 * 255);
    //15+1-bit (Photoshop range 0-32768)
    R15 = Math.floor(32768 * R01 + 0.5);
    G15 = Math.floor(32768 * G01 + 0.5);
    B15 = Math.floor(32768 * B01 + 0.5);
    //10-bit (0-1023)
    R10 = Math.floor(1023 * R01 + 0.5);
    G10 = Math.floor(1023 * G01 + 0.5);
    B10 = Math.floor(1023 * B01 + 0.5);
    //16-bit (0-65535)
    G16 = Math.floor(65535 * G01 + 0.5);
    R16 = Math.floor(65535 * R01 + 0.5);
    B16 = Math.floor(65535 * B01 + 0.5);
    //32-bit (0-4294967295)
    R32 = Math.floor(4294967295 * R01 + 0.5);
    G32 = Math.floor(4294967295 * G01 + 0.5);
    B32 = Math.floor(4294967295 * B01 + 0.5);
  }
  return {
    sX, sY, sZ,
    Xd: X, Yd: Y, Zd: Z,
    ExtendedR : RGB.R, ExtendedG: RGB.G, ExtendedB:RGB.G,
    R01, G01, B01,
    ClippedR, ClippedG, ClippedB,
    R8bit, G8bit, B8bit,
    NR8b, NG8b, NB8b,
    R15, G15, B15,
    R10, G10, B10,
    R16, G16, B16,
    R32, G32, B32,
    destinationLab
  };
};

export default XyzToRgb;
